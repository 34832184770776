export default {
  idObjectFormatter: function (value, key /*, item*/) {
    if (value == null) return;
    const k = key.toUpperCase();
    switch (k) {
      case "ID":
      case "UNIQUEID":
        return value.substr(0, 8);
      case "CREATED":
      case "LASTEXECUTIONDATE":
      case "CHANGED":
      case "STARTDT":
      case "ENDDT":
      case "LASTREQUEST":
      case "TIMEZONELASTUPDATEDT":
      case "LOCATIONLASTUPDATEDT":
      case "LASTDATARECEIVEDDT":
      case "NOTCONNECTEDNOTIFICATIONDT":
      case "MEASURED":
      case "BEGINDATE":
      case "ENDDATE":
      case "DEVICEDATE": {
        let d = new Date(value);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
      }
    }
  },

  booleanFormatter: function (value) {
    if (value == null || value.length == 0) {
      return "";
    } else {
      return value ? "+" : "-";
    }
  },

  // process entity list response in case of an unknown item count
  processEntityListResponse: function (
    wasResponse,
    response,
    currentPage,
    perPage,
    returnTotalCount
  ) {
    if (!returnTotalCount) {
      // if server returned no data
      if (!response.data || (response.data.length === 0 && currentPage > 1)) {
        wasResponse.totalCount = (currentPage - 1) * perPage; // block "Next" button in pagination
        wasResponse.noData = true;

        return wasResponse; // render previous data
      }

      if (currentPage === 1 && response.data.length < perPage) {
        response.totalCount = response.data.length;
        return response;
      }

      // has data, check its length
      if (response.data.length === perPage) {
        // if it MAYBE exceeds show per page
        response.totalCount = 1e6; // render next button
      } else {
        response.totalCount = currentPage * perPage; // block "Next" button in pagination
      }
    }

    return response;
  },

  formatListToString: function (list) {
    let result = "";
    for (const device of list) {
      result = result + device.name + ", ";
    }
    return result.substring(0, result.length - 2);
  },
};
